<template>
    <section class="solicitud-usuario-ver pl-3 pl-md-0 pl-md-5 ml-md-4">
         <div class="row my-4">
            <div class="col-12">
                <el-breadcrumb separator-class="el-icon-arrow-right f-15">
                    <el-breadcrumb-item :to="{ name: 'viviendas.solicitudes' }">Solicitudes de residentes</el-breadcrumb-item>
                    <el-breadcrumb-item>Solicitud</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 col-sm-12 col-md-5 col-lg-4 my-4">
                <div class="d-flex">
                    <div class="circle-icon bg2 rounded-circle f-30 d-middle-center mr-3">
                        <i class="icon-account-outline op-05" />
                    </div>
                    <div>
                        <p class="f-20 f-500">{{ residente.nombre }}</p>
                        <p class="f-14 text-86">Residente - Vivienda {{ tipoResidente(residente.arriendo)}}</p>
                    </div>
                </div>
                <p class="f-15 mt-4 f-500">{{ residente.vivienda }}</p>
                <div class="row my-lg-5">
                    <div class="col-12">
                        <p class="f-14 text-86">Solicitado: {{ formatearFecha(residente.updated_at)}}</p>
                    </div>
                    <div class="col-12 mt-4">
                        <div class="row acciones">
                            <div class="col-auto pr-1">
                                <button class="btn bg-f5 text-86 border br-4 py-1 f-14 f-300" @click="$refs.abrirDenegarSolicitud.toggle()">Denegar</button>
                            </div>
                            <div class="col-auto pl-1">
                                <button class="btn btn-general br-4 py-1 f-14 f-300" @click="modalAceptarSolicitud(residente)">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-5 col-lg-5 col-lg-4 col-xl-3 mt-3">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                        <div class="d-flex">
                            <div class="circle-icon-mini bg-telefono rounded-circle d-middle-center my-auto">
                                <i class="icon-cellphone op-05 f-18" />
                            </div>
                            <div class="ml-2">
                                <p class="f-12">Número de celular</p>
                                <p class="f-14 f-500">{{ residente.telefono || 'No registra'}}</p> 
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                        <div class="d-flex">
                            <div class="circle-icon-mini bg-email rounded-circle d-middle-center my-auto">
                                <i class="icon-email op-05 f-14 pr-1" />
                            </div>
                            <div class="ml-2">
                                <p class="f-12">Correo electrónico</p>
                                <p class="f-14 f-500 word-break">{{ residente.correo || 'No registra' }}</p> 
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                        <div class="d-flex">
                            <div class="circle-icon-mini bg-cumpleanos rounded-circle d-middle-center my-auto">
                                <i class="icon-birthday op-05 f-18" />
                            </div>
                            <div class="ml-2">
                                <p class="f-12">Fecha de nacimiento</p>
                                <p class="f-14 f-500">{{ formatearFecha(residente.nacimiento) }} </p> 
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2" >
                        <div class="d-flex">
                            <div class="circle-icon-mini bg-genero rounded-circle d-middle-center my-auto">
                                <i class="icon-gender op-05" />
                            </div>
                            <div class="ml-2">
                                <p class="f-12">Género</p>
                                <p class="f-14 f-500">{{ genero(residente.genero)}}</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 my-4">
                <div class="row">
                     <div class="col-12 my-2 pl-55px">
                        <p class="f-16 f-500">Datos adicionales</p>
                    </div>
                    <div v-if="residente.mascotas.length > 0" class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 border-bottom">
                        <div v-for="(mascota,k) in residente.mascotas" :key="k" class="d-flex my-2">
                            <div class="circle-icon-mini bg-db rounded-circle d-middle-center my-auto">
                                <i class="icon-paw op-05 f-19" />
                            </div>
                            <div class="ml-2">
                                <p class="f-14 f-500">{{ mascota.nombre }}</p>
                                <p class="f-12">{{ raza(mascota.tipo, mascota.otro) }}</p> 
                            </div>
                        </div>
                    </div>
                    <div v-if="residente.vehiculos.length > 0" class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 border-bottom">
                        <div v-for="(vehiculo,v) in residente.vehiculos" :key="v" class="d-flex my-2">
                            <div class="circle-icon-mini bg-db rounded-circle d-middle-center my-auto">
                                <i class="icon-car op-05 f-22" />
                            </div>
                            <div class="ml-2">
                                <p class="f-14 f-500">{{tipoVehiculo(vehiculo.tipo)}}: {{vehiculo.marca}} / {{vehiculo.color}}</p>
                                <p class="f-12">{{vehiculo.placas}}</p> 
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 border-bottom">
                        <div class="d-flex my-2">
                            <div class="circle-icon-mini bg-db rounded-circle d-middle-center my-auto">
                                <i class="icon-broom op-05 f-24" />
                            </div>
                            <div class="ml-2">
                                <p class="f-14 f-500">Servicio domestico</p>
                                <p class="f-12">{{ servicioDomestico(residente.servicio_domestico) }}</p> 
                            </div>
                        </div>
                    </div>
                    <div class="col-12 my-2 pl-55px">
                        <p class="f-16 f-500">Residentes en la vivienda</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                        <div v-for="(r,key) in residente.residentes" :key="key" class="d-flex my-2">
                            <div class="circle-icon-mini bg-db rounded-circle d-middle-center my-auto">
                                <i class="icon-account-checked-outline op-05 f-18" />
                            </div>
                            <div class="ml-2">
                                <p class="f-14 f-500">{{ r.nombre }} ( {{ r.edad }} años)</p>
                                <p class="f-12">{{r.parentesco}}</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- partials -->
        <modal-aceptar-residente ref="abrirModalAceptarResidente" @cargar="aceptarResidente"/>
        <modal ref="abrirDenegarSolicitud" titulo="Denegar solicitud" adicional="Denegar" @adicional="rechazarResidente" >
            <p class="f-15 mb-4 text-center">¿Desea denegar el acceso a este usuario?</p>
            <el-input type="textarea" v-model="comentario" placeholder="Por favor escriba una justificación" show-word-limit maxlength="300" class="w-100" :rows="4"/>
        </modal>
    </section>
</template>
<script>
import Usuarios from "@/services/usuarios"
import Viviendas from "@/services/viviendas"
export default {
    components:{
        modalAceptarResidente: () => import('./partials/modalAceptarResidente')
    },
    data(){
        return{
            residente: {},
            comentario: null,
        }
    },
    mounted(){
        this.getUser()
    },
    methods:{
        modalAceptarSolicitud(usuario){
            const params = {
                vivienda : usuario.vivienda,
                propietario : usuario.propietario,
                residente : usuario.residente,
                idVivienda : usuario.id_vivienda,
                idUser: usuario.id
            }

            this.$refs.abrirModalAceptarResidente.toggle(params)
        },
        denegarSolicitud(){
            this.$refs.abrirDenegarSolicitud.toggle()
        },
        async getUser(){
            try {
                let id_residente = this.$route.params.id_residente
                const { data } = await Viviendas.getUserPorId({id_residente})

                this.residente = data.data
            } catch (error) {
                this.errorCatch(error)
            }
        },
        genero(genero){
            switch (genero) {
                case 2:
                    return "Masculino"
                case 3:
                    return "Femenino"
                default:
                    return "Otro"
            }
        },
        tipoResidente(tipo){
            switch (tipo) {
                case 1:
                    return "en arriendo"
                case 2:
                    return "propia"
            
                default:
                    break;
            }
        },
        async aceptarResidente(){
            this.$router.replace('/viviendas/solicitudes')
            this.userSelected = null
        },

        async rechazarResidente(){
            try {

                if(!this.comentario) return this.notificacion('','Campo justificación no valido','warning')

                let params = {
                    id_usuario: this.$route.params.id_residente,
                    comentario: this.comentario,
                }

                const { data } = await Usuarios.rechazarResidente(params)

                this.notificacion('','Residente rechazado correctamente','success')
                this.$refs.abrirDenegarSolicitud.toggle()

                this.$router.replace('/viviendas/solicitudes')
                this.userSelected = null
            } catch (error) {
                this.errorCatch(error)
            }
        },
        raza(tipo, otro){
            switch (tipo) {
                case 1:
                    return "Perro"
                case 2:
                    return "Gato"
                default:
                    return otro
            }
        },
        tipoVehiculo(tipo){
            switch (tipo) {
                case 1:
                    return "Moto"
                case 2:
                    return "Carro"
                default:
                    return "Camioneta"
            }
        },
        servicioDomestico(tipo){
            switch (tipo) {
                case 0:
                    return "No hay"
                case 1:
                    return "Esporádico"
                case 2:
                    return "Medio Tiempo"
                case 3:
                    return "Tiempo Completo"
                default:
                    return "No hay"
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.solicitud-usuario-ver{
    .circle-icon{
        min-width: 52px;
        max-width: 52px;
        min-height: 52px;
        max-height: 52px;
    }
    .circle-icon-mini {
        min-width: 32px;
        max-width: 32px;
        min-height: 32px;
        max-height: 32px;
    }
    .acciones{
        .btn{
            min-width: 130px;
            height: 32px;
        }
    }
}

.pl-55px{
    padding-left: 55px;
}
</style>